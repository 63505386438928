@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-endorsement-block {
    background-color: white;

    .homepage-endorsement-block-container {
        padding: 48px 0;
        margin: 0 24px;
        background-color: $neutral-10;
        border-radius: 16px;
        gap: 40px;

        @include media-breakpoint-up(md) {
            margin: 0 80px;
            gap: 48px;
            padding: 80px 0;
        }
    }

    .homepage-endorsement-block-heading {
        margin: 0;
        padding: 0 24px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 1400px;
            padding: 0 80px;
        }
    }

    .homepage-endorsement-block-wrapper {
        padding: 0 24px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            padding: 0 80px;
        }
    }

    .swiper-wrapper {
        &.with-gap {
            gap: 16px;
        }
    }

    .homepage-endorsement-block-card {
        gap: 32px;
        padding: 48px 40px;
        background-color: $white;
        border-radius: 24px;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            gap: 40px;
            padding: 48px 48px 56px 48px;
        }

        &.limit-width {
            max-width: 100%;

            @include media-breakpoint-up(md) {
                max-width: 540px;
            }
        }
    }

    .homepage-endorsement-block-card-heading,
    .homepage-endorsement-block-card-body-text,
    .swiper-homepage-endorsement-block-desktop {
        gap: 16px;
    }

    .homepage-endorsement-block-card-heading-text {
        gap: 4px;
    }

    .homepage-endorsement-block-card-heading-name {
        color: $neutral-80;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    .homepage-endorsement-block-card-heading-job {
        color: $neutral-70;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @include media-breakpoint-up(md) {
            font-weight: 600;
        }
    }

    .homepage-endorsement-block-card-heading-image {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 64px;
    }

    .homepage-endorsement-block-card-quote-pull {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;

        @include media-breakpoint-up(md) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    .homepage-endorsement-block-card-quote-body {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .swiper-pagination-endorsement-block {
        gap: 16px;

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            background-color: $neutral-20;

            &.swiper-pagination-bullet-active {
                background-color: $dark-green;
            }
        }
    }
}
